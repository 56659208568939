import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['closeButton', 'tooltip', 'content', 'toggleDropDown']

  closeButtonTargetConnected() {
    this.closeButtonTargets.forEach((button, _i) => {
      let data = button.dataset.dismiss
      let fadeTarget = button.closest('.' + data)
      button.addEventListener('click', (_event) => {
        let fadeEffect = setInterval(function () {
          if (!fadeTarget.style.opacity) { fadeTarget.style.opacity = 1; }
          if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
          } else {
            clearInterval(fadeEffect);
            fadeTarget.remove()
          }
        }, 30);
      })

      setTimeout(()=> {
        fadeTarget.remove()
      }, 6000)
    });
  }
  closeButtonTargetDisconnected() {}

  tooltipTargetConnected(target) {
    tippy(target, {
      content: target.dataset.tooltipContent || target.querySelector('.tooltip'),
      allowHTML: true,
      zIndex: 999999,
    });
  }

  contentTargetConnected(target) {
    let headerHeight = 0;
    let contentHeaderHeight = 0
    let contentFooterHeight = 0

    if (document.querySelector('header')) {
      headerHeight = document.querySelector('header').offsetHeight
    }
    if (document.querySelector('.content-header')) {
      contentHeaderHeight = document.querySelector('.content-header').offsetHeight
    }
    if (document.querySelector('.content-footer')) {
      contentFooterHeight = document.querySelector('.content-footer').offsetHeight
    }
    let height = window.innerHeight - (headerHeight + contentHeaderHeight)
    target.style.height = `${height-5}px`
    let columns = target.querySelectorAll('.column-wrapper')
    columns.forEach(column => {
      column.style.height = `${height - contentFooterHeight - 6}px`
    });
  }

  toggleDropdown(event) {
    const parent = event.currentTarget.closest('.dropdown');
    parent.classList.toggle('active');
  }

  toggleDropDownTargetConnected(target) {
    const parent = target.closest('.dropdown');
    document.addEventListener('click', (event)=> {
      if (event.target.closest('.dropdown') == parent) return
      parent.classList.remove('active')
    })
  }

  hideDropdown(event) {
    const parent = event.currentTarget.closest('.dropdown');
    parent.classList.remove('active');
  }

  toggleSidebar() {
    let pageWrapper = document.querySelector('.page-wrapper');
    if (pageWrapper.classList.contains('sidebar-hidden')) {
      pageWrapper.classList.remove('sidebar-hidden')
    } else {
      pageWrapper.classList.add('sidebar-hidden')
    }
  }

  scrollTo(event) {
    var fullUrl, parts, offset, trgt;
    event.preventDefault();
    const element = event.currentTarget;
    fullUrl = element.getAttribute('href');
    parts = fullUrl.split("#");
    trgt = parts[1];
    offset = document.querySelector(`#${trgt}`).offsetTop;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }
}
