import { Controller } from '@hotwired/stimulus'
import { lookupRelationsByEids } from 'helpers/admin_paired_relations'
import { dedup } from 'helpers'

export default class extends Controller {
  static targets = ['source', 'suggestions']

  reload() {
    const adminPairedRelations = this.application.getControllerForElementAndIdentifier(this.element, 'admin--paired-relations')
    const eidsValue = adminPairedRelations.eidsValue
    const suggestedEids = this._buildSuggestions().filter((eid) => !eidsValue.includes(eid))
    const results = lookupRelationsByEids(dedup(suggestedEids))

    const suggestionsHtml = results.map((res) => {
      return this._renderSuggestion({
        value: res.eid,
        result: this._suggestionValue(res)
      })
    })
    this.suggestionsTarget.innerHTML = this._renderSuggestions(suggestionsHtml.join(''))
  }

  _buildSuggestions() {
    return this.sourceTargets.map((el) => el.value)
      .filter((val) => val)
      .flatMap((val) => {
        // find all relations inputs with current value, case insensitive
        return Array.from(document.querySelectorAll('.relations input'))
          .filter((input) => val.localeCompare(input.value, undefined, { sensitivity: 'accent' }) === 0)
          // remove relations from tabs not marked for import
          .filter((input) => {
            return input.closest('.tab-content')
                        .querySelector('[data-field-name="import"]')
                        .checked
          })
          // find eid of the relation
          .map((input) => input.closest('.relations').dataset.eid)
      })
  }

  _suggestionValue(relationResult) {
    const mediatorVariables = relationResult.mediatorVariables.map((med) => med ? med : '???')
    return [
      `[${relationResult.tab.name}]`,
      `${relationResult.abbr}:`,
      relationResult.independentVariable, '->', relationResult.dependentVariable,
      relationResult.supported == 'yes' ? '[Supported]' : '[Unsupported]',
      relationResult.impact ? `[${relationResult.impact}]` : '',
      relationResult.partialFull ? `[${relationResult.partialFull}]` : '',
      [
        ' ',
        relationResult.moderatorVariable ? `Moderator: ${relationResult.moderatorVariable}` : '',
        mediatorVariables.length !== 0 ? `Mediators: ` + mediatorVariables.join(', ') : ''
      ].filter((item) => item).join('<br />')
    ].join(' ')
  }

  _renderSuggestion(result) {
    return `<div class="result" data-action="click->admin--autocomplete#setValue"
                         data-value=${result.value} data-result="${result.value}">
      ${result.result}
    </div>`
  }

  _renderSuggestions(results) {
    return `<div class="autocomplete-results">
      ${results}
    </div>`
  }
}
