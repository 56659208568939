import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'trigger', 'target' ]

  connect() {
    this.toggle()
  }

  triggerTargetConnected(target) {
    this.toggle()
    target.addEventListener('change', () => { this.toggle() })
  }

  toggle() {
    const expectedValues = (this.triggerTarget.dataset.visibilityTogglerValues || '').split(',')
    const triggerValue = this.triggerTarget.value

    if (expectedValues.includes(triggerValue)) {
      this.targetTarget.classList.remove('hidden')
    } else {
      this.targetTarget.classList.add('hidden')
    }
  }
}
