import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['sentence', 'sentenceRaw'];

  openHighlightModal(event) {
    event.preventDefault();

    const sentence = this.sentenceTarget?.value?.trim();
    const sentenceRaw = this.sentenceRawTarget?.value?.trim();

    if (!sentence && !sentenceRaw) return;

    const studyFrame = document.querySelector("#admin_studies_study_body");

    if (studyFrame && studyFrame.innerHTML.trim() !== "") {
      this.highlightSentence(sentence, sentenceRaw);
    } else {
      studyFrame.addEventListener("turbo:frame-load", () => {
        this.highlightSentence(sentence, sentenceRaw);
      }, { once: true });

      studyFrame.src = event.currentTarget.href;
    }
  }

  highlightSentence(sentence, sentenceRaw) {
    const studyFrame = document.querySelector("#admin_studies_study_body");
    if (!studyFrame) return;

    const studyBody = studyFrame.querySelector(".box-content");
    if (!studyBody) return;

    studyBody.querySelectorAll(".sentence-highlight").forEach(el => {
      el.outerHTML = el.innerHTML;
    });

    const matchRaw = sentenceRaw ? studyBody.innerHTML.match(new RegExp(_.escapeRegExp(sentenceRaw), "i")) : null;
    const match = matchRaw || (sentence ? studyBody.innerHTML.match(new RegExp(_.escapeRegExp(sentence), "i")) : null);

    if (match) {
      studyBody.innerHTML = studyBody.innerHTML.replace(
        match[0],
        `<span class="sentence-highlight">${match[0]}</span>`
      );

      const highlightedElement = studyBody.querySelector(".sentence-highlight");
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }
}
