import { Controller } from '@hotwired/stimulus';
import { findRelationByEid } from 'helpers/admin_paired_relations';
import { dedup } from 'helpers';

export default class extends Controller {
  static targets = ['template', 'element', 'input', 'tabs', 'tabContent'];
  static outlets = ['annotate'];

  connect() {
    this.tabMap = {};
  }

  inputTargetConnected(element) {
    if (element.value) {
      element.dataset.previousValue = element.value
    }
  }

  push(event) {
    const groupedFields = event.currentTarget.closest('.grouped-fields');
    const fieldName = event.currentTarget.dataset.fieldName;
    const newValue = groupedFields?.querySelector('[data-field-name="variable"]')?.value;
    const abbrValue = groupedFields?.querySelector('[data-field-name="abbr"]')?.value;

    if (!newValue) return;

    this.newVarValue = newValue;
    const matchingElements = this._getMatchingElements(fieldName, abbrValue, newValue, event.currentTarget);

    if (fieldName === 'variable') {
      this._updateCurrentElement(event.currentTarget, newValue);
    }

    const uniqueMatchingElements = dedup(
      matchingElements.map(el => this._resolveRelatedElement(el, newValue)).filter(Boolean)
    );

    uniqueMatchingElements.forEach(el => this._renderInput(el));

    if (uniqueMatchingElements.length > 0) {
      this.dispatch('push', { detail: { boxId: 'propagate_changes' } });
    }
  }

  apply(event) {
    this.elementTargets.forEach(target => {
      if (target.checked) {
        const element = document.getElementById(target.value);
        element.value = this.newVarValue;
        this.annotateOutlets.forEach(outlet => {
          if (outlet.element.contains(element)) {
            outlet.updateText({ target: element });
          }
        });
      }
    });

    this.dispatch('apply', { target: event.currentTarget });
    this.clearModalContent();
  }

  _getMatchingElements(fieldName, abbrValue, newValue, currentElement) {
    return Array.from(document.querySelectorAll('input'))
      .filter(el => el.dataset.fieldName?.includes('variable') || el.dataset.fieldName?.includes('abbr'))
      .filter(el => {
        if (fieldName === 'abbr' && abbrValue && this._ciEquals(el.value, abbrValue)) return true;
        if (fieldName === 'variable') {
          const previousValue = currentElement.dataset.previousValue;
          return (
            (previousValue && this._ciEquals(el.value, previousValue)) ||
            (abbrValue && this._ciEquals(el.value, abbrValue))
          );
        }
        return false;
      });
  }

  _updateCurrentElement(element, newValue) {
    element.setAttribute('value', newValue);
    element.dataset.previousValue = newValue;
  }

  _resolveRelatedElement(element, newValue) {
    const fieldName = element.dataset.fieldName;
    const groupedFields = element.closest('.grouped-fields');
    const mapping = {
      independent_abbr: 'independent_variable',
      dependent_abbr: 'dependent_variable',
      moderator_abbr: 'moderator_variable',
      mediator_abbr: 'mediator_variable',
    };

    const relatedFieldName = mapping[fieldName];
    if (relatedFieldName) {
      return groupedFields?.querySelector(`[data-field-name="${relatedFieldName}"]`);
    }

    if (this._ciEquals(element.value, newValue)) return null;

    return fieldName?.includes('variable') ? element : null;
  }

  _renderInput(element) {
    const elementLocation = this._findTabName(element);
    const data = findRelationByEid(element.closest('.result-box').dataset.eid);

    let content = this.templateTarget.innerHTML.replace(/TMPLID/g, element.id);
    const oldValue = `<span style="color: red; text-decoration: line-through;">${element.value}</span>`;
    const newValue = `<span style="color: green;">${this.newVarValue}</span>`;

    content = this._generateContent(content, element, elementLocation, data, oldValue, newValue);

    this._addContentToTab(elementLocation, content);
  }

  _generateContent(content, element, elementLocation, data, oldValue, newValue) {
    if (elementLocation.startsWith('Var')) {
      return content.replace(
        /TMPLLABEL/g,
        `Update ${oldValue} to ${newValue}`
      );
    }

    const { independentVariable, dependentVariable, moderatorVariable, mediatorVariables } = data;
    const moderatorPart = moderatorVariable
      ? ` <span class="implication-icon coral"><i class="fas fa-xmark"></i></span> ${moderatorVariable}`
      : '';
    const mediatorPart = mediatorVariables
      .map(variable => `<span class="implication-icon soft-sky"><i class="fas fa-right-long"></i></span> ${variable}`)
      .join(' ');

    return this._mapFieldContent(element.dataset.fieldName, {
      content,
      oldValue,
      newValue,
      independentVariable,
      dependentVariable,
      moderatorPart,
      mediatorPart,
    });
  }

  _mapFieldContent(fieldName, params) {
    const {
      content,
      oldValue,
      newValue,
      independentVariable = '',
      dependentVariable = '',
      moderatorPart = '',
      mediatorPart = '',
    } = params;

    let updatedMediatorPart = mediatorPart;

    if (fieldName === 'mediator_variable') {
      // Use a more robust replacement method for mediatorPart
      const oldValueText = this._stripHtmlTags(oldValue);
      const regex = new RegExp(oldValueText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'); // Escape special characters
      updatedMediatorPart = mediatorPart.replace(
        regex,
        `${oldValue} ${newValue}`
      );
    }

    const templates = {
      independent_variable: `${oldValue} ${newValue} ${moderatorPart} ${mediatorPart} <span class="implication-icon"><i class="fas fa-right-long"></i></span> ${dependentVariable}`,
      dependent_variable: `${independentVariable} ${moderatorPart} ${mediatorPart} <span class="implication-icon"><i class="fas fa-right-long"></i></span> ${oldValue} ${newValue}`,
      moderator_variable: `${independentVariable} <span class="implication-icon"><i class="fas fa-xmark"></i></span> ${oldValue} ${newValue} ${mediatorPart} <span class="implication-icon"><i class="fas fa-right-long"></i></span> ${dependentVariable}`,
      mediator_variable: `${independentVariable} ${moderatorPart} ${updatedMediatorPart} <span class="implication-icon"><i class="fas fa-right-long"></i></span> ${dependentVariable}`,
    };

    return content.replace(/TMPLLABEL/g, templates[fieldName] || '');
  }


  _addContentToTab(tabName, content) {
    const sanitizedTabName = tabName.replace(/[^a-zA-Z0-9-_]/g, '-');
    const tabId = `tab-${sanitizedTabName}`;

    if (!this.tabMap[tabName]) {
      const tab = document.createElement('li');
      tab.classList.add('tab-link');

      const isFirstTab = Object.keys(this.tabMap).length === 0;

      if (isFirstTab) {
        tab.classList.add('current');
      }

      tab.innerHTML = `<a href="#${tabId}" data-action="click->propagate-changes#showTab">${tabName}</a>`;
      this.tabsTarget.appendChild(tab);

      const tabPanel = document.createElement('div');
      tabPanel.id = tabId;
      tabPanel.classList.add('tab-panel');
      tabPanel.dataset.tabName = tabName;
      this.tabContentTarget.appendChild(tabPanel);

      this.tabMap[tabName] = tabPanel;
    }

    this.tabMap[tabName].insertAdjacentHTML('beforeend', content);
  }

  showTab(event) {
    event.preventDefault();
    const targetTabId = event.currentTarget.getAttribute('href').replace('#', '');

    this.tabContentTarget.querySelectorAll('.tab-panel').forEach(panel => {
      panel.style.display = 'none';
    });

    const targetPanel = this.tabContentTarget.querySelector(`#${targetTabId}`);
    if (targetPanel) targetPanel.style.display = 'block';

    this.tabsTarget.querySelectorAll('li').forEach(tab => {
      tab.classList.remove('current');
    });

    event.currentTarget.parentElement.classList.add('current');
  }

  clearModalContent() {
    this.tabsTarget.innerHTML = '';
    this.tabContentTarget.innerHTML = '';
    this.tabMap = {};
  }

  _findTabName(element) {
    const tabContent = element.closest('.tab-content');
    return document.querySelector(`[data-tab="${tabContent?.id}"]`)?.innerText || 'Unknown';
  }

  _ciEquals(a, b) {
    return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
  }

  _stripHtmlTags(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  }
}
