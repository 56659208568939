import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['content']

  connect() {
    const ctrl = this

    tippy(this.element, {
      content: 'Loading...',
      allowHTML: true,
      zIndex: 999999,
      onShow(instance) {
        ctrl.contentTarget.disabled = false
        ctrl.contentTarget.loaded.then(() => {
          instance.setContent(ctrl.contentTarget.innerHTML)
        })
      }
    });
  }
}
