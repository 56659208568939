import { Controller } from '@hotwired/stimulus'
import _ from 'lodash';
import { parseRelation } from 'helpers/admin_paired_relations'

export default class extends Controller {
  static targets = ['table', 'element']
  static classes = ['highlighter']

  elementTargetConnected(target) {
    target.addEventListener('focus', this.highlight.bind(this))
    target.addEventListener('blur', this.clear.bind(this))
    target.addEventListener('input', this.replace.bind(this))
  }

  elementTargetDisconnected(target) {
    target.removeEventListener('focus', this.highlight.bind(this))
    target.removeEventListener('blur', this.clear.bind(this))
    target.removeEventListener('input', this.replace.bind(this))
  }

  replace({ target }) {
    this.clear()
    this.highlight({ target })
  }

  highlight({ target }) {
    if (!this.tableTarget.querySelector('table')) { return }

    const relation = parseRelation(target.closest('[data-eid]'))
    const abbrs = [relation.independentAbbr, relation.dependentAbbr, relation.moderatorAbbr, ...relation.mediatorAbbrs].filter((e) => e)
    const vars = [relation.independentVariable, relation.dependentVariable, relation.moderatorVariable, ...relation.mediatorVariables].filter((e) => e)
    const all = vars.concat(abbrs)

    const rows = Array.from(this.tableTarget.querySelectorAll('tr'))

    let matchingRow;
    if (relation.abbr) { matchingRow ||= rows.find((row) => this._match(relation.abbr, row)) }
    if (all.length > 0) { matchingRow ||= rows.find((row) => all.every((item) => this._match(item, row))) }
    if (vars.length > 0) { matchingRow ||= rows.find((row) => vars.every((item) => this._match(item, row))) }
    if (abbrs.length > 0) { matchingRow ||= rows.find((row) => abbrs.every((item) => this._match(item, row))) }

    if (all.length > 0) { matchingRow ||= rows.find((row) => all.some((item) => this._match(item, row))) }

    if (matchingRow) {
      matchingRow.classList.add(this.highlighterClass)
      matchingRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  clear() {
    Array.from(this.tableTarget.getElementsByClassName(this.highlighterClass))
        .forEach((el) => el.classList.remove(this.highlighterClass))
  }

  // construct regex to match whole words only or surrounded by whitespace
  _match(item, row) {
    const reg = new RegExp(`(\\b|\\W)${_.escapeRegExp(item)}(\\b|\\W)`, 'i')
    return reg.test(Array.from(row.children).map((td) => td.innerText).join(' '))
  }
}
