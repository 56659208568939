import { Controller } from '@hotwired/stimulus'
import { lookupRelationsByEids } from 'helpers/admin_paired_relations'

export default class extends Controller {
  static targets = ['results']
  static values = { eids: Array, inputName: String }

  connect() {
    this._renderPairedRelations()
  }

  remove( { params } ) {
    if (!params.eid) { return }

    this.eidsValue = this.eidsValue.filter((eid) => eid != params.eid)
  }

  update({ target }) {
    if (!this.eidsValue.includes(target.value)) {
      this.eidsValue = this.eidsValue.concat([target.value])
    }
    target.value = ''

    this._renderPairedRelations()
  }

  _renderPairedRelations() {
    if (!this.hasResultsTarget) { return }

    const results = lookupRelationsByEids(this.eidsValue)

    this.resultsTarget.innerHTML = results.map((result) => this._renderPairedRelation(result)).join('')
  }

  _renderPairedRelation(result) {
    const id = Math.random()
    const partialFull = !result.partialFull ? '' : `<div class="field xs">
        <span class="label">
          <label for="partial-full-${id}">Partial/Full?</label>
        </span>
        <span class="value">
          <input disabled="disabled" id="partial-full-${id}" type="text" value="${result.partialFull}">
        </span>
      </div>`

    return `<div class="nested-form-wrapper nested-form-box" data-new-record="true">
              <div class="grouped-fields">
                <div class="field s">
                  <label for="tab-${id}">
                    <a class="var" data-action="click->tabs#switchTo"
                                   data-tabs-tab-id-param="${result.tab.id}"
                                   href="javascript:void(0)">Tab</a>
                  </label>
                  <input disabled="disabled" id="tab-${id}" type="text" value="${result.tab.name}">
                </div>
                <div class="field xs">
                  <label for="abbr-${id}">Hyp</label>
                  <input disabled="disabled" id="abbr-${id}" type="text" value="${result.abbr}">
                </div>
                <div class="field">
                  <label for="independent-${id}">Independent</label>
                  <input disabled="disabled" id="independent-${id}" type="text" value="${result.independentVariable}">
                </div>
                <div class="field">
                  <label for="dependent-${id}">Dependent</label>
                  <input disabled="disabled" id="dependent-${id}" type="text" value="${result.dependentVariable}">
                </div>
                <input name="${this.inputNameValue}" id="eid-${id}" type="hidden" value="${result.eid}">
              </div>
              <a class="remove-button"
                data-action="admin--paired-relations#remove nested-form#remove"
                data-admin--panel-form-target="removeButton"
                data-admin--paired-relations-eid-param="${result.eid}"
                href="javascript:void(0);">
                <i class="fas fa-minus-circle" aria-hidden="true"></i>
              </a>
              <div class="bottom">
                <div class="box-left">
                  ${this._renderModeratorVariable(result, id)}
                  ${this._renderMediatorVariables(result, id)}
                </div>
                <div class="box-right">
                  <div class="field xs">
                    <span class="label">
                      <label for="supported-${id}">Supported?</label>
                    </span>
                    <span class="value">
                      <input disabled="disabled" id="supported-${id}" type="text" value="${result.supported}">
                    </span>
                  </div>
                  <div class="field s">
                    <span class="label">
                      <label for="impact-${id}">Impact</label>
                    </span>
                    <span class="value">
                      <input disabled="disabled" id="impact-${id}" type="text" value="${result.impact}">
                    </span>
                  </div>
                  ${partialFull}
                </div>
              </div>
            </div>`
  }

  _renderModeratorVariable(result, id) {
    if (!result.moderatorVariable) { return '' }

    return `<div class="label">Moderator var</div>
            <div class="grouped-fields">
              <div class="field">
                <input disabled="disabled" id="moderator-var-${id}" type="text" value="${result.moderatorVariable}">
              </div>
            </div>`
    }

  _renderMediatorVariables(result, id) {
    if (result.mediatorVariables.length === 0) { return '' }

    const rendered = result.mediatorVariables.map((variable, i) =>
      `<div class="grouped-fields">
        <div class="field">
          <input disabled="disabled" id="moderator-var-${id}${i}" type="text" value="${variable}">
        </div>
      </div>`
    ).join('')

    return `<div class="label">Mediator vars</div>${rendered}`
  }
}
